@import '~styles/mixins';

.subs {
  max-width: rem(600px);
  width: 100%;
  margin: 0 auto;
  padding-bottom: rem(40px);
  .subs__list {
    padding: 0px 15px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(25px);
    width: 100%;
    margin-bottom: 30px;
    @media (max-width: 420px) {
      padding: 0px;
    }
    .subs__item {
      display: flex;
      align-items: center;
      gap: 15px;
      width: 100%;
      .sub__avatar {
        position: relative;
        width: 50px;
        height: 50px;
        img {
          border-radius: 15px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .sub__username {
        font-weight: 400;
        font-size: rem(18px);
        line-height: rem(21px);
      }
    }
  }
  @media (max-width: rem(530px)) {
    padding: 0px 5px;
  }
}

.link {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.loaderWrapper {
  width: 100%;
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(40px);

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
}
