@import '~styles/mixins';
@import '~styles/variables';

.header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 99;
  background: #13111c;
  align-items: center;
  justify-content: center;
  min-height: rem(72px);
  height: auto;
  padding: 0 rem(22px);

  @media (max-width: rem(840px)) {
    height: rem(85px);
    border: unset;
  }
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

@keyframes gradientTransfusionBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.container {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: rem(30px);

  @media (max-width: rem(450px)) {
    gap: rem(5px);
  }

  .leftPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(20px);
    @media (max-width: 550px) {
      gap: 0;
    }
  }

  .rightPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: end;
    max-width: rem(670px);
    gap: rem(24px);

    // @media (max-width: rem(1440px)) {
    @media (max-width: rem(1160px)) {
      align-items: center;
      gap: rem(10px);
    }
  }

  .headerInstruments {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(18px);
    user-select: none;
    @media (max-width: 680px) {
      gap: rem(12px);
    }
    @media (max-width: 450px) {
      gap: rem(5px);
    }
  }

  .notificationsBell {
    position: relative;
    display: flex;
    transition: all 200ms ease-in-out;
    align-items: center;
    padding: rem(14px);
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      background-color: #282631;
    }

    @media (max-width: 930px) {
      position: static;
    }
    @media (max-width: 400px) {
      padding: rem(12px);
    }

    @media (max-width: 360px) {
      padding: rem(8px);
    }
  }

  @media (max-width: rem(1150px)) {
    .dropdowns > *:not(.notificationsBell) {
      display: none;
    }
  }

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: rem(5px) rem(12px);
    gap: rem(10px);
    position: relative;
    border-radius: rem(200px);
    transition: background-color 200ms linear;

    &:hover {
      background-color: #282631;
    }

    @media (max-width: 380px) {
      padding: rem(2px) rem(4px);
    }

    .link {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .nickname {
      font-weight: 500;
      font-size: rem(17px);
      line-height: rem(21px);
      transition: 0.3s;
      display: flex;
      align-items: center;
      gap: rem(10px);

      @media (max-width: rem(500px)) {
        display: none;
      }
    }

    .avatarWrapper {
      width: rem(40px);
      height: rem(40px);
      box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
      position: relative;
      border-radius: rem(35px);
      overflow: hidden;

      &::before {
        z-index: -1;
        content: '';
        position: absolute;
        top: rem(1px);
        bottom: rem(2px);
        left: rem(1px);
        right: rem(1px);
        border-radius: 100%;
        background: #1a1a23;
      }

      .avatar {
        object-fit: cover;
        border-radius: 100%;
        border: rem(3px) solid #282535;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'logo navigation controls';
  column-gap: rem(10px);
  row-gap: rem(25px);
  min-width: 0;
  @media (max-width: rem(867px)) {
    grid-template-columns: auto auto;
    grid-template-areas: 'logo controls' 'navigation navigation';
  }

  .logo {
    grid-area: logo;
    margin-right: auto;
  }

  .navigation {
    grid-area: navigation;
    margin-left: auto;
    margin-right: auto;
  }

  .controls {
    grid-area: controls;
    margin-left: auto;
  }
}

.heading {
  text-align: center;
  font-size: rem(20px);
  font-weight: 500;
  line-height: rem(24px);
  @media (max-width: rem(767px)) {
    font-size: rem(15px);
    line-height: rem(18px);
  }
}

.logo {
  align-items: center;
  gap: rem(10px);
  flex-direction: row;
  position: relative;
  display: none;

  @media (max-width: rem(1160px)) {
    display: flex;
  }

  &Image {
    display: block;
    width: rem(31.5px);
    height: rem(20px);
    object-fit: contain;
    @media (max-width: rem(800px)) {
      width: rem(25px);
      height: rem(16px);
    }
  }

  &Text {
    font-size: rem(23px);
    font-weight: 600;
    line-height: rem(30px);
    @media (max-width: rem(800px)) {
      font-size: rem(20px);
      line-height: rem(24px);
    }
  }
}

.navigation {
  width: rem(350px);
  display: flex;
  gap: rem(10px);
  padding: rem(4px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  border-radius: rem(15px);
  min-width: 0;
  @media (max-width: rem(767px)) {
    max-width: rem(325px);
  }
  @media (max-width: rem(575px)) {
    max-width: rem(300px);
  }

  &Button {
    flex-basis: 50%;
    text-align: center;
    font-size: rem(15px);
    line-height: rem(18px);
    font-weight: 500;
    border-radius: rem(15px);
    padding: rem(5px) rem(10px);
    transition:
      background-color 0.3s ease,
      box-shadow 0.3s ease;
    @media (max-width: rem(575px)) {
      font-size: rem(12px);
      line-height: rem(16px);
    }

    &Active {
      background-color: var(--background-color);
      box-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.25);
    }
  }
}

.controls {
  display: flex;
  align-items: center;
}

.auth {
  display: flex;
  align-items: center;
  gap: rem(5px);
  margin-right: rem(10px);
  @media (max-width: rem(575px)) {
    display: none;
  }

  &Button {
    opacity: 1;
    transition: color 0.3s ease;

    &:hover {
      color: #c400d6;
    }
  }
}

.buttonPro {
  display: none;
  margin-right: rem(30px);
  text-transform: uppercase;
  font-size: rem(18px);
  font-weight: 700;
  line-height: rem(22px);
  padding: rem(4px) rem(12px);
  border-radius: rem(10px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  @media (max-width: rem(767px)) {
    font-size: rem(15px);
    line-height: rem(18px);
    padding: rem(3px) rem(9px);
    margin-right: rem(10px);
  }
}

.buttonFiller {
  width: rem(36px);
  height: rem(36px);
}

.loader {
  width: rem(24px);
  height: rem(24px);

  svg {
    width: rem(26px);
    height: rem(26px);
  }
}

.navItem {
  display: flex;
  transition: all 200ms linear;
  align-items: center;
  cursor: pointer;
  padding: rem(14px);
  border-radius: 100%;

  &:hover {
    background-color: #282631;
  }

  @media (max-width: 400px) {
    padding: rem(12px);
  }

  @media (max-width: 360px) {
    padding: rem(8px);
  }
}

.chats {
  @media (max-width: 1150px) {
    display: none;
  }
}

.navItemActive {
  background-color: #282631;
}

.navbarWrapper {
  margin: rem(100px) auto 0 0;
}

.iconSetting {
  display: flex;
  width: rem(26px);
  height: rem(26px);
  align-items: center;
}

.glareButton {
  font-size: rem(16px);
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: rem(200px);
  padding: rem(12px) rem(20px);
  background-color: #1e1c27;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: background 50ms linear;
  gap: rem(10px);

  &:hover {
    background: #282631;
  }

  @media (max-width: 760px) {
    font-size: rem(13px);
  }

  @media (max-width: 680px) {
    display: none;
  }

  .updateToPremium {
    white-space: nowrap;
  }

  .textPremium {
    color: #a74bff;
    font-weight: 700;
  }
}

.glareButton:before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 5s infinite linear;
}

@keyframes shine {
  0% {
    left: -100px;
  }
  10% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.activeNotificationBell {
  background: #282631;
}

.notificationss {
  @media (max-width: 930px) {
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    border-left: rem(16px) solid transparent;
    border-right: rem(16px) solid transparent;
    border-bottom: rem(16px) solid #1f1d28;
    width: 0;
    height: 0;

    @media (max-width: 930px) {
      display: none;
    }
  }
}
