@import '~styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(18px);
  padding: rem(16px);
  padding-bottom: rem(24px);
  background: #191724;
  border-radius: rem(26px);
  width: 100%;

  .imageWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    border-radius: rem(26px);

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cardBottom {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
  }
  .textWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;

    .text {
      overflow: hidden;
      color: #d1d1d6;
      font-size: rem(15px);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      word-break: break-all;
      .buttonTextContainer {
        .buttonTextHide {
          margin-left: rem(5px);
          text-decoration: underline;
          transition: color 200ms linear;
          &:hover {
            color: #fff;
          }
        }
        .buttonTextShow {
          text-decoration: underline;
          transition: color 200ms linear;
          &:hover {
            color: #fff;
          }
        }
      }

      @media (max-width: rem(400px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
    }

    .postCopy {
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
      fill: white;

      @media (max-width: rem(400px)) {
        width: rem(30px);
        height: rem(30px);
      }
      &:hover {
        background: #282631;
      }
    }
  }
  .cardTags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: rem(6px);
    .textStyleContainer {
      margin: rem(10px) 0;
    }
    .textStyle {
      position: relative;
      padding: rem(6px) rem(12px);
      border-radius: 30px;
      border: 1px solid transparent;
      background:
        linear-gradient(#282631, #282631) padding-box,
        linear-gradient(116deg, #9e5992 3.98%, #6a19ab 58.61%, #3054bb 112.12%)
          border-box;
      text-align: center;

      @media (max-width: 600px) {
        font-size: rem(12px);
      }
    }
  }
}
.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
