@import '~styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(22px);

  .publishWrapper {
    display: flex;
    flex-direction: column;
    gap: rem(22px);
    padding: rem(22px);
    border: 2px solid #1f1d28;
    border-radius: rem(26px);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

    .title {
      font-weight: 600;
      font-size: rem(18px);
    }
    .subscribers {
      display: flex;
      width: max-content;
      gap: rem(12px);
      align-items: center;
      color: #d1d1d6;
      font-weight: 500;
      font-size: rem(16px);
      cursor: pointer;
      padding-bottom: rem(6px);
      .input {
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 6px;
        border: 2px solid #383744;
        background: transparent;
        cursor: pointer;
        &:checked {
          border: 2px solid #fff;
        }
        &:checked::before {
          content: url('../../components/icons/CheckedIcon.svg');
          display: block;
          font-size: 16px;
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
}
